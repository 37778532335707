<template>
  <b-modal
    v-model="data.showModal"
    size="xl"
    button-size="sm"
    modal-class="template-modal"
    title-class="w-full"
    footer-class="w-full"
    no-fade
    @show="onShow"
    @hide="onHide"
    hide-header-close
    scrollable
  >
    <!-- Modal header -->
    <div slot="modal-title">
      <div class="flex w-full justify-between">
        Sample Report
      </div>
    </div>

    <!-- Modal content -->
    <div v-if="report" class="flex flex-col gap-2">
      <h3 class="font-bold text-lg">Burn Test</h3>
      <table class="table table-google table-highlight">
        <thead>
          <tr>
            <th class="text-nowrap row-fit">Date</th>
            <th class="text-nowrap row-fit">User</th>
            <th class="text-nowrap row-fit">Cold Throw</th>
            <th class="text-nowrap row-fit">Flame</th>
            <th class="text-nowrap row-fit">Melt Pool</th>
            <th class="text-nowrap row-fit">Hot Throw</th>
            <th class="text-nowrap row-fit">Smoke</th>
            <th class="text-nowrap row-fit">Jar temp.</th>
            <th class="text-nowrap row-fit">Result</th>
            <th class="text-nowrap">Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="test in report.burntest" :key="`burntest_${test.id}`">
            <td>{{ test.sample_date | isodate }}</td>
            <td>{{ test.assignee }}</td>
            <td class="text-right">{{ test.cold_throw }}</td>
            <td>{{ test.flame | capitalize }}</td>
            <td class="text-right">{{ test.melt_pool }}</td>
            <td class="text-right">{{ test.hot_throw }}</td>
            <td>{{ test.smoke }}</td>
            <td>{{ test.jar_temp }}</td>
            <td>{{ test.sample_result | capitalize }}</td>
            <td>{{ test.sample_note }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Modal footer -->
    <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
      <div class="flex w-full justify-content-between">
        <div>
          <b-btn
            size="sm"
            variant="primary"
            :disabled="isLoading || !canSave"
            @click="onSave"
          >
            Save
          </b-btn>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
// const DropdownText = () => import('@/components/global/DropdownText.vue');

export default {
  name: 'ReportModal',
  components: {},
  props: {
    data: Object,
    id: Number,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      return true;
    },
    preventMessage() {
      return 'You have unsaved changes. Are you sure you want to close?';
    },
    preventClose() {
      return false;
    },
  },
  data() {
    return {
      loadingCount: 0,
      filters: {},
      options: {},
      report: null,
    };
  },
  methods: {
    fetchReport() {
      this.loadingCount++;
      this.$http
        .get(`/candle/${this.id}/sampleReport`)
        .then((res) => {
          this.report = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch report: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onSave() {
      // Implement logic
    },
    onShow() {
      this.fetchReport();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
    onHide(event) {
      if (this.preventClose && !confirm(this.preventMessage)) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
</style>
